<template>
  <div class="section-content section-space-ships">
    <div class="container-fluid">
      <div
        class="heading mb-4 d-flex align-items-center justify-content-between"
      >
        <div>
          <h2 class="font-semi-bold">Edit Space Ship Page</h2>
          <p class="subtitle">Edit data space ship.</p>
        </div>

        <a @click.prevent="$router.back()" class="icon font-semi-bold">
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
          >
            <path
              d="M1.4793 6.65259H16.2606C16.4567 6.65259 16.6446 6.73045 16.7832 6.86906C16.9218 7.00766 16.9997 7.19564 16.9997 7.39165C16.9997 7.58767 16.9218 7.77565 16.7832 7.91425C16.6446 8.05286 16.4567 8.13072 16.2606 8.13072H1.4793C1.28329 8.13072 1.0953 8.05286 0.956702 7.91425C0.8181 7.77565 0.740234 7.58767 0.740234 7.39165C0.740234 7.19564 0.8181 7.00766 0.956702 6.86906C1.0953 6.73045 1.28329 6.65259 1.4793 6.65259Z"
              fill="#101010"
            />
            <path
              d="M1.78543 7.3916L7.91525 13.5199C8.05403 13.6587 8.13199 13.8469 8.13199 14.0432C8.13199 14.2395 8.05403 14.4277 7.91525 14.5665C7.77647 14.7052 7.58825 14.7832 7.39199 14.7832C7.19573 14.7832 7.00751 14.7052 6.86873 14.5665L0.217129 7.91486C0.148302 7.84621 0.0936956 7.76465 0.0564371 7.67486C0.0191786 7.58507 0 7.48881 0 7.3916C0 7.29439 0.0191786 7.19813 0.0564371 7.10834C0.0936956 7.01855 0.148302 6.937 0.217129 6.86834L6.86873 0.216741C7.00751 0.0779639 7.19573 0 7.39199 0C7.58825 0 7.77647 0.0779639 7.91525 0.216741C8.05403 0.355518 8.13199 0.54374 8.13199 0.74C8.13199 0.936261 8.05403 1.12448 7.91525 1.26326L1.78543 7.3916Z"
              fill="#101010"
            />
          </svg>

          Back
        </a>
      </div>

      <div class="content">
        <div class="row mb-3">
          <div class="col">
            <div class="card card-form border-0">
              <div class="card-body">
                <form @submit.prevent="save" v-if="isLoaded">
                  <div class="row no-gutters">
                    <div class="col-md-12">
                      <img
                        v-lazy="`${urlImg}${image_temporary}`"
                        :alt="form.spaceship_name"
                        width="250"
                        height="250"
                        class="d-block mb-2 img-contain"
                      />

                      <div class="form-group">
                        <label for="image">Image</label>

                        <b-form-file
                          v-model="form.image"
                          :state="Boolean(form.image)"
                          accept="image/*"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </div>

                      <div class="form-group">
                        <label for="name">Name</label>
                        <input
                          v-model="form.name"
                          type="text"
                          class="form-control"
                          id="name"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="about_en">About English</label>
                        <textarea
                          v-model="form.about_en"
                          class="form-control"
                          id="about_en"
                          rows="5"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="about_id">About Indonesia</label>
                        <textarea
                          v-model="form.about_id"
                          class="form-control"
                          id="about_id"
                          rows="5"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="producer_en">Producer English</label>
                        <textarea
                          v-model="form.producer_en"
                          class="form-control"
                          id="producer_en"
                          rows="5"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="producer_id">Producer Indonesia</label>
                        <textarea
                          v-model="form.producer_id"
                          class="form-control"
                          id="producer_id"
                          rows="5"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="mission_en">Mission English</label>
                        <textarea
                          v-model="form.mission_en"
                          class="form-control"
                          id="mission_en"
                          rows="5"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="mission_id">Mission Indonesia</label>
                        <textarea
                          v-model="form.mission_id"
                          class="form-control"
                          id="mission_id"
                          rows="5"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="release_en">Release English</label>
                        <textarea
                          v-model="form.release_en"
                          class="form-control"
                          id="release_en"
                          rows="5"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="release_id">Release Indonesia</label>
                        <textarea
                          v-model="form.release_id"
                          class="form-control"
                          id="release_id"
                          rows="5"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-12 d-flex justify-content-end mt-3">
                      <button
                        v-if="!isSubmit"
                        type="submit"
                        class="btn btn-grey px-4 py-2"
                      >
                        Save Data
                      </button>
                      <button
                        v-else
                        class="
                          btn btn-grey
                          px-4
                          py-2
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                        disabled
                      >
                        <b-spinner class="mx-3" height="1rem"></b-spinner>
                      </button>
                    </div>
                  </div>
                </form>

                <div v-else class="row no-gutters">
                  <div class="col text-center text-black my-3">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Services
import { toastedOption, capitalize } from "@/services/general.js";
import { showSpaceShip, editSpaceShip } from "@/services/admin/SpaceShips.js";

export default {
  name: "AdminSpaceShipsEdit",
  metaInfo: {
    title: "Admin Space Ships - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  data() {
    return {
      urlImg: process.env.VUE_APP_STORAGE_URL,
      image_temporary: null,
      isLoaded: false,
      isSubmit: false,
      form: {
        image: null,
        name: "",
        about_en: "",
        about_id: "",
        producer_en: "",
        producer_id: "",
        mission_en: "",
        mission_id: "",
        release_en: "",
        release_id: "",
      },
    };
  },
  mounted() {
    this.spaceship();
  },
  methods: {
    spaceship() {
      showSpaceShip(this.$route.params.id).then((response) => {
        const data = response.data.body.data.ship;

        this.image_temporary = data.image;
        this.form.name = data.name;
        this.form.about_en = data.about_en;
        this.form.about_id = data.about_id;
        this.form.producer_en = data.producer_en;
        this.form.producer_id = data.producer_id;
        this.form.mission_en = data.mission_en;
        this.form.mission_id = data.mission_id;
        this.form.release_en = data.release_en;
        this.form.release_id = data.release_id;

        this.isLoaded = true;
      });
    },
    save() {
      const formData = new FormData();
      formData.append(
        "image",
        this.form.image ? this.form.image : this.image_temporary
      );
      formData.append("name", this.form.name);
      formData.append("about_en", this.form.about_en);
      formData.append("about_id", this.form.about_id);
      formData.append("producer_en", this.form.producer_en);
      formData.append("producer_id", this.form.producer_id);
      formData.append("mission_en", this.form.mission_en);
      formData.append("mission_id", this.form.mission_id);
      formData.append("release_en", this.form.release_en);
      formData.append("release_id", this.form.release_id);

      this.isSubmit = true;

      editSpaceShip(this.$route.params.id, formData)
        .then(() => {
          this.$toasted.success("Edit success", toastedOption);
          this.$router.back();
        })
        .catch((error) => {
          this.isSubmit = false;

          error.response.data.body.errors.forEach((message) => {
            this.$toasted.error(capitalize(message.msg), toastedOption);
          });
        });
    },
  },
};
</script>